import './HowItsDoneView.css';
import { Grid } from '@mui/material';
function HowItsDoneView() {
    
    return (
        <Grid item xs={12} md={8} className="howitsdone" >
            <h2>How Does It Work?</h2>
            <p>It's simple. When you place an order with us, you'll have the opportunity to upload a model that represents your desired design.</p>
            <p>Our pricing is determined by several factors, including the size of the model, the chosen material, and whether you would like an engineer to assist with refining and polishing the final product. We believe in offering personalized options to meet your specific needs and preferences.</p>
            <p>Once we receive your order, our team will promptly notify you and begin the meticulous machining process. Depending on the material you choose, our skilled machinists will either 3D print or utilize CNC technology to create your unique nipple model. Rest assured, we take pride in maintaining the highest standards of craftsmanship and attention to detail.</p>
            <p>After the machining process is complete, we will carefully package and ship your custom nipple model directly to your home. We understand the importance of privacy and discretion, and we handle all orders with the utmost care and respect.</p>
        </Grid>
    );
}
    
export default HowItsDoneView;

    
    
    
    
    