import './FeaturesView.css';
import { Grid } from '@mui/material';
function FeaturesView() {
    
    
    return (
        <Grid container item xs={9} md={8} className="features" >
            <Grid item xs={12} >
                <h2>Our Services</h2>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12} md={3} className="feature">
                    <div className='featureImage'>
                        <img src="cnc.jpg" alt="Breast Memento Service" />
                    </div>
                    <h3>Nipple Printing Service</h3>
                    <p>Create a custom 3D-printed or CNC model of your nipples as a powerful memento, capturing the beauty and uniqueness of your sexy physique.</p>
                </Grid>
                <Grid item xs={12} md={3} className="feature">
                    <div className='featureImage'>
                        <img src="community.jpg" alt="Supportive Community" />
                    </div>
                    <h3>Supportive Community</h3>
                    <p>Connect with fellow breast cancer survivors, share stories, find resources, and embrace a community that celebrates resilience and humor.</p>
                </Grid>
                <Grid item xs={12} md={3} className="feature">
                    <div className='featureImage'>
                        <img src="education.jpg" alt="Educational Resources" />
                    </div>
                    <h3>Educational Resources</h3>
                    <p>Access a wealth of informative articles, empowering books, and helpful resources to navigate the challenges of breast cancer and post-mastectomy life.</p>
                </Grid>
                <Grid item xs={12} md={3} className="feature">
                    <div className='featureImage'>
                        <img src="charity.jpg" alt="Nipple Foundation" />
                    </div>
                    <h3>Nipple Foundation</h3>
                    <p>Empowering Lives, Embracing Courage: The Nipple Foundation</p>
                </Grid>
            </Grid>
        </Grid>
    );
}
    
export default FeaturesView;

