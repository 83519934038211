import './WaitlistView.css';
import React, { useState } from "react";
// import React, { useEffect } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Fade from '@mui/material/Fade';
import { getFunctions, httpsCallable } from "firebase/functions";
import { initializeApp } from 'firebase/app';



function WaitlistView() {
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [emailValidation, setEmailValidation] = useState(false);

    const app = initializeApp({
        apiKey: "AIzaSyBdL0Ejw5xyO9JqDrD1sYWAI7YXgEe3YpU",
        authDomain: "nipple-design.firebaseapp.com",
        projectId: "nipple-design",
        storageBucket: "nipple-design.appspot.com",
        messagingSenderId: "740533410118",
        appId: "1:740533410118:web:a2af2a080be79c5583ad47",
        measurementId: "G-7SL7MK4SG7"
      });

    const functions = getFunctions();
    const waitlistSignupEmail = httpsCallable(functions, 'waitlistSignupEmail');

    function handleEmail(event){
        setEmail(event.target.value);
        console.log(event.target.value);
        if(/^[A-Za-z0-9+_.-]+@[A-Za-z0-9.-]+$/.test(event.target.value)){
            console.log("Valid email")
            setEmailValidation(true)
        } else {
            setEmailValidation(false)
        }
    }

    function handleSnackbarClose(event, reason){
        if(reason === "clickaway"){
            return;
        } else {
            setOpen(false)
        }
    }

    function onWaitlistSignupButtonClick (event) {
        // const email = document.getElementById("signup-email");
        // console.log(emailRef.current.value);
        if(/^[A-Za-z0-9+_.-]+@[A-Za-z0-9.-]+$/.test(email)){
            waitlistSignupEmail({ email: email })
            .then((result) => {
                // Read result of the Cloud Function.
                /** @type {any} */
                // const data = result.data;
                setOpen(true);
                console.log(result);
                // console.log(sanitizedMessage);
            });
        } 
    }
    
    return (
        <Grid container justifyContent="center" item spacing={2} xs={12} className="WaitlistView" alignItems="stretch">

            <Grid item xs={12}>
                <Snackbar autoHideDuration={3000} anchorOrigin={{vertical: 'top', horizontal: 'center'}} onClose={handleSnackbarClose} open={open} TransitionComponent={Fade}>
                    <Alert variant="filled" severity="success">
                        Thank you for signing up, we'll be in touch!
                    </Alert>
                </Snackbar>    
            </Grid>
            <Grid item xs={3}>
                <TextField id="signup-email" label="Email" variant="outlined" onBlur={handleEmail} error={!emailValidation} fullWidth />
            </Grid>
            <Grid item xs={2}>
                <Button onClick={onWaitlistSignupButtonClick} disabled={!emailValidation} variant="contained" endIcon={<SendIcon />} sx={{height: "100%"}}>
                Sign Up
                </Button>
            </Grid>
        </Grid>
    );
}
    
export default WaitlistView;