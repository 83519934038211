import logo from './logo.svg';
import './App.css';
import FeaturesView from './FeaturesView';
import IntroView from './IntroView';
import MissionView from './MissionView';
import WaitlistView from './WaitlistView';

import { Grid } from '@mui/material';
import { Item } from '@mui/material';
import HowItsDoneView from './HowItsDoneView';
import STLView from './STLView';



function App() {
  function componentDidMount(){
    console.log("Component Mounted")
    window.addEventListener("scroll", function () {
      const parallax = document.querySelector(".parallax");
      let offset = window.pageYOffset;
      parallax.style.backgroundPositionY = offset * 0.7 + "px";
    });
  };

  return (
    <Grid container justifyContent="center" spacing={0}>
      <Grid item xs={12} className="header" >
        <h1>nipple.design</h1>
      </Grid>
      <Grid container justifyContent="center" item xs={12} className="parallax parallaxOne" >
        <Grid item xs={8} className="parallax-content" >
          <h2>Cheers to Nipple Power!</h2>
          <p>We believe in celebrating strength, resilience, and the power of laughter. Join us on a journey of self-acceptance, sensuality, and joyful healing.</p>
        </Grid>
      </Grid>
      <MissionView />
      <IntroView />
      <STLView />
      <HowItsDoneView />
      
      <WaitlistView />
      <FeaturesView />
      <Grid item xs={12} className="parallax parallaxTwo" ></Grid>
      <Grid item xs={12} className="footer" >
          <p>&copy; 2023 nipple.design. All rights reserved.</p>
      </Grid>

    </Grid>
  );
}

export default App;
