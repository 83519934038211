import './IntroView.css';
import { Grid } from '@mui/material';
function IntroView() {
    
    return (
        <Grid item xs={12} md={8} className="intro" >
            <h2>What We Do</h2>
            <p>We offer the opportunity to preserve a tangible memory of one's body. Our meticulous machining process ensures the utmost precision and attention to detail, resulting in beautifully crafted models that serve as personal mementos and symbols of sexuality.</p>
            <p>Whether the model is for personal gain or to be a gift for someone else, we want to help you make something you want to have on display. We work closely with our machinist to provide quality materials and if necessary, our engineers can work with you to sculpt any issues during the capture process.</p>
        </Grid>
    );
}
    
export default IntroView;

    
    
    
    
    