import {StlViewer} from "react-stl-viewer";
import React, { useState } from "react";
import { Grid } from "@mui/material";

function STLView() {
  const [url, setURL] = useState("/models/Breast-A.stl");
  const style = {
      top: 0,
      left: 0,
      width: '100%',
      height: '50%',
      minHeight: '50vh'
  }
  const initModelProps = {
    scale: 2,
    rotationX: Math.PI/2,
    rotationY: -Math.PI/6,
    rotationZ: 3*Math.PI/2
  }
  function componentDidMount(){

  };

  return (
    <Grid item xs={6} className="STLView">
      <StlViewer
          style={style}
          orbitControls
          // shadows
          url={url}
          modelProps={initModelProps}
      />
    </Grid>
  );
}

export default STLView;