import './MissionView.css';
import { Grid } from '@mui/material';
function MissionView() {
    
    
    return (
        <Grid item xs={12} md={8} className="mission" >
            <h2>Our Mission</h2>
            <p>At nipple.design, our mission is to uplift our community with a touch of sass and a whole lot of love. We believe that laughter, even with a naughty twist, can be a powerful healing force. We provide a one-of-a-kind service that combines cheekiness and creativity, allowing people to embrace and showcase their bodies while unleashing their inner vixens.</p>
            <p>With our nipple service, we offer individuals a chance to capture a cherished memory of their 'twins' for any occasion, whether it be a gag gift for a birthday or a memento before a mastectomy. Our process blends state-of-the-art technology with a sprinkle of naughtiness. We're all about empowering people to reclaim their sensuality and rock their unique curves.</p>
            <p>At nipple.design, we're breaking taboos with a wink, embracing individuality, and fostering a supportive community that's unafraid to have a little fun. With our foundation, we create a safe haven where survivors can share their stories, find resources, and connect with like-minded warriors who understand the power of laughter.</p>
            <p>Join us on this audacious journey of self-acceptance, resilience, and joyful rebellion. Together, let's celebrate the fierce spirit of the community, one nipple at a time.</p>
        </Grid>
    );
}
    
export default MissionView;

